






import { Component, Vue, Provide } from 'vue-property-decorator';
import QuestionProvider from '@/providers/questions.provider';
import CourseList from '../../shared/components/CourseList.component.vue';
import { APPIDS, QUESTIONS_ROUTES_NAMES } from '../../enums';

@Component({
  components: { CourseList },
})
export default class QuestionsCourseList extends Vue {
  @Provide() Provider = QuestionProvider;

  appId = APPIDS.CREATE_QUESTIONS;

  name = 'questionsCourseList';

  goQuestionsList(courseData) {
    this.$router.push({
      name: QUESTIONS_ROUTES_NAMES.QUESTIONS_LIST,
      params: {
        courseId: courseData.courseId.toString(),
      },
    });
  }
}
